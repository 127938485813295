import React from 'react';
import './closing.css';


class Closing extends React.Component{


    render(){
    if(this.props.show && this.props.over ==false){
    if(this.props.winner){  
        var messageEnd = "Congrats, you won!"
    }else{
        var messageEnd = "Good game! Better luck next time!"
    }
        return(   
        <div id='closingpage' className='closing-page'>
            <div id='closing-message' >{messageEnd}</div>
            <div  className='review3' onClick={(event)=>this.props.reviewGameOver(event)} >Review Game</div>
            <div  className='review3' onClick={(event)=>this.props.newGame(event)} >New Game</div>

        </div>
        )
    }
    else{
        return (<div></div>)
    }
  }
  }

export default Closing