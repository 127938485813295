import wp from "./img/wp.png"
import bp from "./img/bp.png"
import wb from "./img/wb.png"
import bb from "./img/bb.png"
import wk from "./img/wk.png"
import bk from "./img/bk.png"
import wr from "./img/wr.png"
import br from "./img/br.png"
import wq from "./img/wq.png"
import bq from "./img/bq.png"
import wc from "./img/wc.png"
import bc from "./img/bc.png"



export function image_opacity(string){
    if(string =="ee"){
      return(0)
    }
    else{
      return(1)
    }
  }

export function checkmate(x,y,board, make, points){

    var check1 = check(x,y,board,make)
    if(check1[0]|| points==39){
      if(x+1 <= 7 && y+1 <=7){
        if(board[x+1][y+1] =="ee"){
          if(check(x+1,y+1,board,make)==false){
            return false
            }
          } 
        }
        if(x+1 <= 7 && y-1 >=0){
          if(board[x+1][y-1] =="ee"){
            if(check(x+1,y-1,board,make)==false){
              return false
            }
          }
        }
        if(x-1 >= 0 && y+1 <=7){
          if(board[x-1][y+1] =="ee"){
            if(check(x-1,y+1,board,make)==false){
              return false
            }
          }
        }
        if(x-1 >=0 && y-1 >=0){
          if(board[x-1][y-1] =="ee"){
            if(check(x-1,y-1,board,make)==false){
              return false
            }
          }
        }
        if(x+1 <=7 ){
          if(board[x+1][y] =="ee"){
            if(check(x+1,y,board,make)==false){
              return false
            }
          }
        }
        if(x-1 >=0 ){
          if(board[x-1][y] =="ee"){
            if(check(x-1,y,board,make)==false){
              return false
            }
          }
        }
        if(y+1 <=7 ){
          if(board[x][y+1] =="ee"){
            if(check(x,y+1,board,make)==false){
              return false
            }
          }
        }
        if(y-1 >=0 ){
          if(board[x][y-1] =="ee"){
            if(check(x,y-1,board,make)==false){
              return false
            }
          }
        }

        if(points ==39){
          return true
        }

        for(var z =0; z<check1[1].length; z++){

          if(check(check1[1][z][0],check1[1][z][1], board, false)[0]==true){
            return false
          }
        }
        return true
      }
    }
  


export function check(x,y,board, make){
    if(make){
    var pawn = "bp"
    var bishop = "bb"
    var rook = "br"
    var knight = "bk"
    var queen = "bq"
    var king = "bc"
    }else{
    var pawn = "wp"
    var bishop = "wb"
    var rook = "wr"
    var knight = "wk"
    var queen = "wq"
    var king = "wc"
    }
    if(make){
    if(x-1 >= 0 && y+1 >=0){
      if(board[x-1][y+1] ==pawn){
        return [true,[[x-1,y+1]]]
      }
    }
    if(x+1 <= 7 && y+1 >= 0){
      if(board[x+1][y+1] == pawn){
        return [true,[[x+1,y+1]]]
      }
    }
  }else{
    if(x-1 >= 0 && y-1 >=0){
      if(board[x-1][y-1] ==pawn){
        return [true,[[x-1,y-1]]]
      }
    }
    if(x+1 <=7 && y-1 >=0){
      if(board[x+1][y-1] == pawn){
        return [true,[[x+1,y-1]]]
      }
    } 
  }
    var n = 1
    while(x + n <= 7){
      if(board[x +n][y]==rook||board[x +n][y]==queen){
        var z = 1
        var temp = []
        while(n >=z){


          temp.push([x+z,y])
          z+=1
        }
        return [true,temp]
      }
      if(board[x +n][y]!="ee"){
        break
      }
      n+=1
    }
    var n = 1
    while(x - n >=0){
      if(board[x -n][y]== rook||board[x -n][y]== queen){
        var z = 1
        var temp = []
        while(z <=n){
          temp.push([x-z,y])
          z+=1
        }
        return [true,temp]
      }
      if(board[x -n][y]!="ee"){
        break
      }
      n+=1
    }
    var n = 1
    while(y+ n <=7){
      if(board[x][y+n]==rook||board[x][y+n]==queen){
        var z = 1
        var temp = []
        while(z <=n){
          temp.push([x,y+z])
          z+=1
        }
        return [true,temp]
      }
      if(board[x][y+n]!="ee"){
        break
      }
      n+=1
    }
    var n = 1
    while(y- n >=0){
      if(board[x][y-n]==rook||board[x][y-n]==queen){
        var z = 1
        var temp = []
        while(z <=n){


          temp.push([x,y-z])
          z+=1
        }
        return [true,temp]
      }
      if(board[x][y-n]!="ee"){
        break
      }
      n+=1
    }
    var n = 1
    while(x- n >=0 && y-n >=0){
      if(board[x-n][y-n]== bishop||board[x-n][y-n]== queen){
        var z = 1
        var temp = []
        while(z <=n){


          temp.push([x-z,y-z])
          z+=1
        }
        return [true,temp]
      }
      if(board[x -n][y-n]!="ee"){
        break
      }
      n+=1
    }
    var n = 1
    while(x+ n <=7 && y-n >=0){
      if(board[x+n][y-n]== bishop||board[x+n][y-n]== queen){
        var z = 1
        var temp = []
        while(z <=n){

          temp.push([x+z,y-z])
          z+=1
        }
        return [true,temp]
      }
      if(board[x +n][y-n]!="ee"){
        break
      }
      n+=1
    }
    var n = 1
    while(x- n >=0 && y+n <=7){
      if(board[x-n][y+n]== bishop||board[x-n][y+n]== queen){
        var z = 1
        var temp = []
        while(z <=n){

          temp.push([x-z,y+z])
          z+=1
        }
        return [true,temp]
      }
      if(board[x -n][y+n]!="ee"){
        break
      }
      n+=1
    }
    var n = 1
    while(x+ n <=7 && y+n <=7){
      if(board[x+n][y+n]== bishop||board[x+n][y+n]== queen){
        var z = 1
        var temp = []
        while(z <=n){

          temp.push([x+z,y+z])
          z+=1
        }
        return [true,temp]
      }
      if(board[x +n][y+n]!="ee"){
        break
      }
      n+=1
    }
    if(x-2 >=0 && y+1 <=7){
      if(board[x-2][y+1]== knight){
        return [true,[[x-2,y+1]]]
      }
    }
    if(x-1 >=0 && y+2 <=7){
      if(board[x-1][y+2]== knight){
        return [true,[[x-1,y+2]]]
      }
    }
    if(x-2 >=0 && y-1 >=0){
      if(board[x-2][y-1]== knight){
        return [true,[[x-2,y-1]]]
      }
    }
    if(x-1 >=0 && y-2 >=0){
      if(board[x-1][y-2]== knight){
        return [true,[[x-1,y-2]]]
      }
    }
    if(x+1 <=7 && y+2 <=7){
      if(board[x+1][y+2]== knight){
        return [true,[[x+1,y+2]]]
      }
    }
    if(x+2 <=7 && y+1 <=7){
      if(board[x+2][y+1]== knight){
        return [true,[[x+2,y+1]]]
      }
    }
    if(x+1 <=7 && y-2 >=0){
      if(board[x+1][y-2]== knight){
        return [true,[[x+1,y-2]]]
      }
    }
    if(x+2 <=7 && y-1 >=0){
      if(board[x+1][y-2]== knight){
        return [true,[[x+1,y-2]]]
      }
    }
    if(x-1 >=0 && y-1 >=0){
      if(board[x-1][y-1]== queen){
        return [true,[[x-1,y-1]]]
      }else if(board[x-1][y-1]== king){
        if(check(x,y,board,true)[0]!=true){
          return [true,[[x-1,y-1]]]
        }
      }
    }
    if(x+1 <=7 && y-1 >=0){
      if(board[x+1][y-1]== queen){
        return [true,[[x+1,y-1]]]
      }else if(board[x+1][y-1]== king){
        if(check(x,y,board,true)[0]!=true){
          return [true,[[x+1,y-1]]]
        }
      }
    }
    if(x-1 >=0 && y+1 <=7){
      if(board[x-1][y+1]== queen){
        return [true,[[x-1,y+1]]]
      }else if(board[x-1][y+1]== king){
        if(check(x,y,board,true)[0]!=true){
          return [true,[[x-1,y+1]]]
        }
      }
    }
    
    if(x+1 <=7 && y+1 <=7){
      if(board[x+1][y+1]== queen){
        return [true,[[x+1,y+1]]]
      }else if(board[x+1][y+1]== king){
        if(check(x,y,board,true)[0]!=true){
          return [true,[[x+1,y+1]]]
        }
      }
    }
    if( y+1 <=7){
      if(board[x][y+1]== queen){
        return [true,[[x,y+1]]]
      }else if(board[x][y+1]== king){
        if(check(x,y,board,true)[0]!=true){
          return [true,[[x,y+1]]]
        }
      }
    }
    if( y-1 >=0){
      if(board[x][y-1]== queen){
        return [true,[[x,y-1]]]
      }else if(board[x][y-1]== king){
        if(check(x,y,board,true)[0]!=true){
          return [true,[[x,y-1]]]
        }
      }
    }
    if( x+1 <=7){
      if(board[x+1][y]== queen){
        return [true,[[x+1,y]]]
      }else if(board[x+1][y]== king){
        if(check(x,y,board,true)[0]!=true){
          return [true,[[x+1,y]]]
        }
      }
    }
    if( x-1 >=0){
      if(board[x-1][y]== queen){
        return [true,[[x-1,y]]]
      }else if(board[x-1][y]== king){
        if(check(x,y,board,true)[0]!=true){
          return [true,[[x-1,y]]]
        }
      }
    }
    return false
  }
  

  export function goodMove(iy,ix,c,board, make){
      var x = c[1]
      var y = c[0]
      var mypiece = board[y][x]
      var theirpiece = board[iy][ix]
      if(iy <= 7 && iy >=0 && ix <=7 && ix >=0){
        var diff1 = y- iy
        var diff2 = x - ix
        var factor1 = 1
        var factor2 = 1
        var ok = true
        if(diff2 < 0){
          factor2 = -1
        }
        if(diff1 < 0){
          factor1 = -1
        }
        if(mypiece[1] ==="p"){
          if(mypiece[0]==="w"){
            if(theirpiece === "ee"){
              if(iy === y-1 && ix === x){
                return true
              }
              else if(y === 6 && board[y-1][x]==="ee" && iy === y-2 && ix===x){
                return true
              }
            }
            else if(theirpiece[0]==="b"){
              if((iy === y-1 && ix === x-1)||(iy === y-1 && ix === x+1)){
                return true
              }
            }
          }
          else{
            if(theirpiece === "ee"){
              if(iy === y+1 && ix === x){
                return true
              }
              else if(y === 1 && board[y+1][x]==="ee" && iy === y+2 && ix===x){
                return true
              }
            }
            else if(theirpiece[0]==="w"){
              if((iy === y+1 && ix === x+1)||(iy === y+1 && ix === x-1)){
                return true
              }
            }
          }
        }else if(mypiece[1]==="r"){
          if(y === iy){
            for(var x1 = 1; x1 < Math.abs(diff2); x1++){
              if(board[y][ix + x1*factor2] !== "ee"){
                ok = false
              }
            }
            return ok
          }
          else if(x ===ix){
            for(var y1 = 1; y1 < Math.abs(diff1); y1++){
              if(board[iy + y1*factor1][x]!== "ee"){
                ok = false
              }
            }
            return ok
          }
        }else if(mypiece[1]==="b"){
          if(Math.abs(diff1) === Math.abs(diff2)){
            for(var x1 = 1; x1 < Math.abs(diff1); x1 ++){
              if(board[iy + x1*factor1][ix + x1*factor2]!== "ee"){
                ok = false
              }
            }
            return ok
          }
        }else if(mypiece[1]==="k"){
          if((Math.abs(diff1) === 2 && Math.abs(diff2)===1)||(Math.abs(diff1) === 1 && Math.abs(diff2)===2)){
            ok = true
          }else{
            ok = false
          }
            return ok
        }
        else if(mypiece[1]==="c"){
          if(Math.abs(diff1) <=1 && Math.abs(diff2)<=1){
            return true
          }else{
            return false
          }
        }
        else if(mypiece[1]==="q"){
          if(y === iy){
            for(var x1 = 1; x1 < Math.abs(diff2); x1++){
              if(board[y][ix + x1*factor2] !== "ee"){
                ok = false
              }
            }
            return ok
          }
          else if(x ===ix){
            for(var y1 = 1; y1 < Math.abs(diff1); y1++){
              if(board[iy + y1*factor1][x]!== "ee"){
                ok = false
              }
            }
            return ok
          }
          else if(Math.abs(diff1) === Math.abs(diff2)){
            for(var x1 = 1; x1 < diff1; x1 ++){
              if(board[iy + x1*factor1][ix + x1*factor2]!== "ee"){
                ok = false
              }
            }
          return ok
          }
        }
      }
    return false
  }

  export function calcPoints(string){
    if(string[1] ==="p"){
      return 1
    }
    else if(string[1] === "k" || string[1] === "b"){
      return 3
    }
    else if(string[1] ==="r"){
      return 5
    }
    else if(string[1] ==="q"){
      return 9
    }
    else if(string[1] === "e"){
      return 0;
    }
  }

  export function other_sidew(list1){

    for(var x = 0; x <list1.length; x++){
      if(list1[x][1]=="q"){
        list1.splice(x, 1);
        list1.push("wp")
        return ["wq", list1]
      }
    }

    for(var x = 0; x <list1.length; x++){
      if(list1[x][1]=="r"){
        list1.splice(x, 1);
        list1.push("wp")
        return ["wr", list1]
      }
    }

    for(var x = 0; x <list1.length; x++){
      if(list1[x][1]=="b"){
        list1.splice(x, 1);
        list1.push("wp")
        return ["wb", list1]
      }
    }

    for(var x = 0; x <list1.length; x++){
      if(list1[x][1]=="k"){
        list1.splice(x, 1);
        list1.push("wp")
        return ["wk", list1]
      }
    }

    for(var x = 0; x <list1.length; x++){
      if(list1[x][1]=="p"){
        return ["wp", list1]
      }
    }

  }


  export function other_sideb(list1){

    for(var x = 0; x <list1.length; x++){
      if(list1[x][1]=="q"){
        list1.splice(x, 1);
        list1.push("bp")
        return ["bq", list1]
      }
    }

    for(var x = 0; x <list1.length; x++){
      if(list1[x][1]=="r"){
        list1.splice(x, 1);
        list1.push("bp")
        return ["br", list1]
      }
    }

    for(var x = 0; x <list1.length; x++){
      if(list1[x][1]=="b"){
        list1.splice(x, 1);
        list1.push("bp")
        return ["bb", list1]
      }
    }

    for(var x = 0; x <list1.length; x++){
      if(list1[x][1]=="k"){
        list1.splice(x, 1);
        list1.push("bp")
        return ["bk", list1]
      }
    }

    for(var x = 0; x <list1.length; x++){
      if(list1[x][1]=="p"){
        return ["bp", list1]
      }
    }

  }

  export function total_points(list){
    var total = 0
    for(var x = 0; x <list.length; x++){
      total = total + calcPoints(list[x])
    }
    return total
  }


  export const image_decide =(make, image)=>{
    if (!image) return
    if(image[0]=="w"){
      if(image[1] == "p"){
        if(make){
          return(wp)
        }else{
          return(bp)
        }
      }
      else if(image[1]=="r"){
        if(make){
          return(wr)
        }else{
          return(br)
        }
      }
      else if(image[1]=="b"){
        if(make){
          return(wb)
        }else{
          return(bb)
        }
      }
      else if(image[1]=="k"){
        if(make){
          return(wk)
        }else{
          return(bk)
        }
      }
      else if(image[1] =="q"){
        if(make){
          return(wq)
        }else{
          return(bq)
        }
      }
      else if(image[1]=="c"){
        if(make){
          return(wc)
        }else{
          return(bc)
        }
      }
    }
    else{
      if(image[1] == "p"){
        if(make){
          return(bp)
        }else{
          return(wp)
        }
      }
      else if(image[1]=="r"){
        if(make){
          return(br)
        }else{
          return(wr)
        }
      }
      else if(image[1]=="b"){
        if(make){
          return(bb)
        }else{
          return(wb)
        }
      }
      else if(image[1]=="k"){
        if(make){
          return(bk)
        }else{
          return(wk)
        }
      }
      else if(image[1] =="q"){
        if(make){
          return(bq)
        }else{
          return(wq)
        }
      }
      else if(image[1]=="c"){
        if(make){
          return(bc)
        }else{
          return(wc)
        }
      }
    }
  }


  export function image_size(image){
    if(image[1] =="q" || image[1]=="c"){
      return("largepiece")
    }
    else if(image[1]=="b"){
      return("medpiece")
    }
    else{
      return("smallpiece")
    }
  }

