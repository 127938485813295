import React from 'react';
import './opening.css';
import logo from "./img/logo.gif"


class Opening extends React.Component{


    render(){
    if(!this.props.show){
    if(this.props.initialSetup){  
        return(   
        <div id='openingPage' className='opening-window'>
            <div className='box'>
            <img className='logo1' src={logo}/>
            <div className='play-outer' onClick={this.props.makeRoom}>
            <div   className='play-button' >Make Room</div>
            </div>
            <div className='play-outer' onClick={this.props.findRoom}>
            <div  className='play-button' >Join Room</div>
            </div>
            </div>
        </div>)
    }
    else{
    if(this.props.make){
    return(
        <div id='openingPage' className='opening-window'>
            <div className='box'>
            <img className='logo1' src={logo}/>
            <p className='instructions1'>To create a room, input a room number.</p>
                <input type="number" placeholder='Room Number' className='input1' onChange={(event)=>this.props.setRoom(event.target.value)}/>
                <p className='instructions1'>Enter the amount of time in minutes each player will have.</p>
                <input  type="number" placeholder='Time (Minutes)' onChange={(event)=>this.props.setLength(event.target.value)} id="game_length" className="input1">
                </input>
                <p className='instructions1'>If nothing happens when "Play!" is clicked it indicates the room number is already in use, so try making a more unique room number.</p>
                <div  className="play-outer" onClick={this.props.startGame}>
                <div   className='play-button'>Play!</div>
                </div>
            </div>
        </div>
    )}else{
        return(
            <div id='openingPage' className='opening-window'>
                <div className='box'>
                <img className='logo1' src={logo}/>
                <p className='instructions1'>To join a game, enter the room number of the game that you would like to joing below</p>
                    <input type="number" className='input1' placeholder='Room Number' onChange={(event)=>this.props.setRoom(event.target.value)}/>
                    <div style={{marginTop: "30px"}} className='play-outer' onClick={this.props.startGame}>
                    <div className='play-button' >Play!</div>
                    </div>
                </div>
            </div>
        )
    }}
    }else{
        return (<div></div>)
    }
}
  }

export default Opening