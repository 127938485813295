import React, {useEffect} from 'react';
import './board.css';
import Points from "./Points.js"
import Slider from "./Slider.js"
import Clock from "./clock.js"
import Opening from "./opening.js"
import Closing from "./closing.js"
import bsq from "./img/bsq.png"
import wsq from "./img/wsq.png"
import {other_sidew,other_sideb, image_decide,image_opacity,goodMove, total_points, image_size, check, checkmate} from './board_functions';
import io from 'socket.io-client'

function Square(props) {


    return (
      <div 
      className="square" 
      onClick={props.onClick}>
        <img className='squarepic' src ={props.src} alt={"square"}/>
        <img src={image_decide(props.make,props.value)} style={{opacity:image_opacity(props.value)}} className={image_size(props.value)}/>
      </div>
    );
  }

  
  class Board extends React.Component {

    renderSquare(i,j,w) {
      return(<Square
      make = {this.props.make}
      src={w}
      value={this.props.squares[i][j]}
      onClick={() => this.props.onClick(i,j)}  />);
    }
  
    render() {


      return (
        <div id='game-board'>
          <div className="board-row">
            {this.renderSquare(0,0, bsq)}
            {this.renderSquare(0,1, wsq)}
            {this.renderSquare(0,2, bsq)}
            {this.renderSquare(0,3, wsq)}
            {this.renderSquare(0,4, bsq)}
            {this.renderSquare(0,5, wsq)}
            {this.renderSquare(0,6, bsq)}
            {this.renderSquare(0,7, wsq)}
          </div><div className="board-row">
            {this.renderSquare(1,0, wsq)}
            {this.renderSquare(1,1, bsq)}
            {this.renderSquare(1,2, wsq)}
            {this.renderSquare(1,3, bsq)}
            {this.renderSquare(1,4, wsq)}
            {this.renderSquare(1,5, bsq)}
            {this.renderSquare(1,6, wsq)}
            {this.renderSquare(1,7, bsq)}
          </div><div className="board-row">{this.renderSquare(2,0, bsq)}{this.renderSquare(2,1, wsq)}{this.renderSquare(2,2, bsq)}{this.renderSquare(2,3, wsq)}{this.renderSquare(2,4, bsq)}{this.renderSquare(2,5, wsq)}{this.renderSquare(2,6, bsq)}{this.renderSquare(2,7, wsq)}</div>
          <div className="board-row">
            {this.renderSquare(3,0, wsq)}
            {this.renderSquare(3,1, bsq)}
            {this.renderSquare(3,2, wsq)}
            {this.renderSquare(3,3, bsq)}
            {this.renderSquare(3,4, wsq)}
            {this.renderSquare(3,5, bsq)}
            {this.renderSquare(3,6, wsq)}
            {this.renderSquare(3,7, bsq)}
          </div><div className="board-row">
            {this.renderSquare(4,0, bsq)}
            {this.renderSquare(4,1, wsq)}
            {this.renderSquare(4,2, bsq)}
            {this.renderSquare(4,3, wsq)}
            {this.renderSquare(4,4, bsq)}
            {this.renderSquare(4,5, wsq)}
            {this.renderSquare(4,6, bsq)}
            {this.renderSquare(4,7, wsq)}
          </div><div className="board-row">
            {this.renderSquare(5,0, wsq)}
            {this.renderSquare(5,1, bsq)}
            {this.renderSquare(5,2, wsq)}
            {this.renderSquare(5,3, bsq)}
            {this.renderSquare(5,4, wsq)}
            {this.renderSquare(5,5, bsq)}
            {this.renderSquare(5,6, wsq)}
            {this.renderSquare(5,7, bsq)}
          </div><div className="board-row">
            {this.renderSquare(6,0, bsq)}
            {this.renderSquare(6,1, wsq)}
            {this.renderSquare(6,2, bsq)}
            {this.renderSquare(6,3, wsq)}
            {this.renderSquare(6,4, bsq)}
            {this.renderSquare(6,5, wsq)}
            {this.renderSquare(6,6, bsq)}
            {this.renderSquare(6,7, wsq)}
          </div><div className="board-row">
            {this.renderSquare(7,0, wsq)}
            {this.renderSquare(7,1, bsq)}
            {this.renderSquare(7,2, wsq)}
            {this.renderSquare(7,3, bsq)}
            {this.renderSquare(7,4, wsq)}
            {this.renderSquare(7,5, bsq)}
            {this.renderSquare(7,6, wsq)}
            {this.renderSquare(7,7, bsq)}
          </div>
        </div>
      );
    }
  }
  
  class RevButton extends React.Component{
    render(){
    if(this.props.show && this.props.winner != ""){
    if(this.props.ok == this.props.status){
      return(<div onClick={()=>this.props.reviewGame(true)} className={this.props.class}>{this.props.name}
      <div className={this.props.class + "2"}>

      </div>
      </div>)
    }else{
      return(<div style={{opacity:0,zIndex:-1}} className={this.props.class}>{this.props.name}
      <div className={this.props.class + "2"}>

      </div>
      </div>)
    }
  }else{
    if(this.props.ok == this.props.status){
      return(<div  className={this.props.class}>{this.props.name}
      <div className={this.props.class + "2"}>

      </div>
      </div>)
    }else{
      return(<div style={{opacity:0,zIndex:-1}} className={this.props.class}>{this.props.name}
      <div className={this.props.class + "2"}>

      </div>
      </div>)
    }
  }}}

  class Game extends React.Component {
      constructor(props){
          super(props);
          this.socket = io("https://ken-chess-test.herokuapp.com/" , { transports : ['websocket'] });
          //this.socket = io.connect("http://localhost:3001")
          this.setLength = this.setLength.bind(this)
          this.jumpTo = this.jumpTo.bind(this)
          this.reviewGame = this.reviewGame.bind(this)
          this.resumeGame = this.resumeGame.bind(this)
          this.reviewGameOver = this.reviewGameOver.bind(this)
          this.newGame = this.newGame.bind(this)
          this.room = ""
          this.winner = ""
          this.state = {
              history:[{
                  squares:[
                    ["br","bk","bb","bq","bc","bb","bk","br"],
                    ["bp","bp","bp","bp","bp","bp","bp","bp"],
                    ["ee","ee","ee","ee","ee","ee","ee","ee"],
                    ["ee","ee","ee","ee","ee","ee","ee","ee"],
                    ["ee","ee","ee","ee","ee","ee","ee","ee"],
                    ["ee","ee","ee","ee","ee","ee","ee","ee"],
                    ["wp","wp","wp","wp","wp","wp","wp","wp"],
                    ["wr","wk","wb","wq","wc","wb","wk","wr"]],
                    blackPieces:[],
                    whitePieces:[],
                    bcount: [0],
                    wcount: [0],
              }],
              whiteNext: true,
              pieceSelected: false,
              king: [7,4],
              selectedCoordinates: [0,0],
              stepNumber: 0,
              stop: false,
              hideOpening: false,
              initial: 90,
              wcount:0,
              bcount:0,
              room: "",
              make: true,
              initialSetup: true,
              closing: false,
              review: false, 
              over: false, 
              go1: false
          }
      }

      initiater(){
        this.state.go1 = true
        this.timer = setInterval(()=>{
          this.updateClock()
        },1000)
      }


      deleter(){
        clearInterval(this.timer)
      }

      updateClock(){
        if(this.state.whiteNext && this.state.stop==false ){
        var newCount1 = parseInt(this.state.bcount) + 6
        this.setState({
            bcount: newCount1,
        })
      }else if(this.state.stop==false){
        var newCount1 = parseInt(this.state.wcount) + 6
        this.setState({
          wcount: newCount1,
      })
      }
      }

      setLength(value){
        var time = parseInt(value)
        this.setState({
          initial: time,
        })
      }

      setRoom(value){
        this.setState({
          room: value,
        })
      }

      makeRoom(){
        this.setState({
          make: true,
          initialSetup: false,
          king:[7,4]
        })
      }

      findRoom(){
        this.setState({
          make: false,
          whiteNext: false,
          initialSetup: false,
          king:[7,3]
        })
      }

      newGame(){
        window.location.reload();
      }


      startGame(event){
        this.socket.emit("join_room",[this.state.room, this.state.make])    
      }

      reviewGameOver(){
        this.setState({
          over: true,
          closing: false
      })
      }

      reviewGame(original){
        if(this.state.stop ==false && this.state.over ==false){
        
        this.deleter()
        const history = this.state.history.slice(0, this.state.stepNumber+1)
        const current = history[history.length - 1];
        const bp = current.blackPieces.slice()
        const wp = current.whitePieces.slice()
        const squares = []
        for(var y1 =0; y1 < current.squares.length; y1++){
          var temp1 = current.squares[y1].slice()
          squares[y1] = temp1
        }
        var whiteadd = current.wcount
        var blackadd = current.bcount
        this.setState({
          history: history.concat([{
            squares: squares,
            blackPieces: bp,
            whitePieces: wp,
            wcount: whiteadd,
            bcount: blackadd,
          }]),
          stepNumber: history.length,
          stop: true,
      })
      if(original){
      this.socket.emit("review_games", this.state.room)
      }
      }}

      resumeGame(original){
        if(this.state.stop == true && this.state.over ==false){
        this.initiater()
        if(this.state.stepNumber == this.state.history.length-1){
          var nextStep = this.state.history.length-1
        }else{
          var nextStep = this.state.stepNumber
        }
        const history = this.state.history.slice(0, this.state.stepNumber+1)
        var hist =[]
        for(var y =0; y < parseInt(nextStep)+1; y++){
          var temp3 = history[y].squares
          var mini_square = []
          for(var x =0; x< temp3.length; x++){
            var temp4 = temp3[x].slice()
            mini_square[x] = temp4
          }
          var bp = history[y].blackPieces.slice()
          var wp = history[y].whitePieces.slice()
          const wcount = history[y].wcount
          const bcount = history[y].bcount
          hist.push({
            squares: mini_square,
            blackPieces: bp,
            whitePieces: wp,
            wcount: wcount,
            bcount: bcount,
          })
        }

        this.setState({
          stop: false,
          history: hist,
          stepNumber: nextStep,
      })
      if(original){
      this.socket.emit("resume_games", this.state.room)
      }
      }}

      handleClick(i,j){
        if(this.state.whiteNext && this.state.over==false && this.state.go1){
        const history = this.state.history.slice(0, this.state.stepNumber+1)
        const current = history[history.length - 1];
        var bp = history[history.length-1].blackPieces.slice()
        var wp = history[history.length-1].whitePieces.slice()
        const squares = []
        for(var y1 =0; y1 < current.squares.length; y1++){
          var temp1 = current.squares[y1].slice()
          squares[y1] = temp1
        }
        const selected = squares[i][j]
        if(this.state.pieceSelected === false){
          if((this.state.whiteNext === false && selected[0]==="b")||(this.state.whiteNext === true && selected[0]==="w")){
            this.state.pieceSelected = true
            this.state.selectedCoordinates=[i,j]
          }
        }
        else{
          if((this.state.whiteNext === false && selected[0]==="b")||(this.state.whiteNext === true && selected[0]==="w")){
            this.state.selectedCoordinates = [i,j]
          }
          else if(goodMove(i,j,this.state.selectedCoordinates,squares, this.state.make)){
            if(squares[i][j]!="ee"){
              var captured = squares[i][j]
              if(this.state.whiteNext && this.state.make){
                wp.push(captured)
              }else if(this.state.whiteNext && !this.state.make){
                bp.push(captured)
              }
              else if(!this.state.whiteNext && this.state.make){
                bp.push(captured)
              }
              else if(!this.state.whiteNext && !this.state.make){
                wp.push(captured)
              }
            }
            if(squares[this.state.selectedCoordinates[0]][this.state.selectedCoordinates[1]] =="wp" && i ==0 && this.state.make){
              var temp = other_sidew(bp)
              squares[i][j] =temp[0]
              bp = temp[1]
            }else if(squares[this.state.selectedCoordinates[0]][this.state.selectedCoordinates[1]] =="wp" && i ==0 && !this.state.make){
              var temp = other_sidew(wp)
              squares[i][j] =temp[0]
              wp = temp[1]
            }else{
              squares[i][j] = squares[this.state.selectedCoordinates[0]][this.state.selectedCoordinates[1]]
            }
            squares[this.state.selectedCoordinates[0]][this.state.selectedCoordinates[1]] = "ee" 
            var whiteadd = this.state.wcount
            var blackadd = this.state.bcount
            var king = this.state.king
            if(squares[i][j]=="wc"){
              king=[i,j]
            }
            if(check(king[0],king[1],squares,true)==false){
            this.setState({
              pieceSelected: false,
              whiteNext: !this.state.whiteNext,
              count:0,
              king:king,
              history: history.concat([{
                squares: squares,
                blackPieces: bp,
                whitePieces: wp,
                wcount: whiteadd,
                bcount: blackadd,
                
              }]),
            stepNumber: history.length
            })
            const squaresN = []
            const blap = []
            const whip = []
            for(var y1 =0; y1 < squares.length; y1++){
              var temp1 = squares[y1].slice()
              var temp2 = []
              for(var x=0; x<temp1.length; x++){
                if(temp1[x][0]=="w"){
                  var new1 ="b"+temp1[x][1]
                }else if(temp1[x][0]=="b"){
                  var new1 ="w"+temp1[x][1]
                }else{
                  var new1 =temp1[x]
                }
                temp2.push(new1)
              }
              squaresN.push(temp2.reverse())
            }
            squaresN.reverse()

            for(var x =0; x < bp.length; x++){
              if(bp[x][0]=="w"){
                var new1 ="b"+bp[x][1]
              }else if(bp[x][0]=="b"){
                var new1 ="w"+bp[x][1]
              }else{
                var new1 =bp[x]
              }
              blap.push(new1)
            }

            for(var x =0; x < wp.length; x++){
              if(wp[x][0]=="w"){
                var new1 ="b"+wp[x][1]
              }else if(wp[x][0]=="b"){
                var new1 ="w"+wp[x][1]
              }else{
                var new1 =wp[x]
              }
              whip.push(new1)
            }

            const messageData = {
              room: this.state.room,
              squares:squaresN,
              blackPieces: blap,
              whitePieces: whip,
              wcount: whiteadd,
              bcount: blackadd,
              stepNumber: history.length
          };
          this.socket.emit("send_message", messageData)
          }
        }
        }
      }}

      componentDidMount() {
        this.socket.open();
        this.socket.on("start_game", (()=>{
          if(this.state.make==false){
            this.socket.emit("start_game", this.state.room)
          }
          this.setState({
            hideOpening: true,
          })
          if(this.state.make==true){
            this.initiater()
            this.socket.emit("give_time", [this.state.room, this.state.initial])
          }
        }))

        this.socket.on("give_timer", (data)=>{
          if(this.state.make==false){
          this.setState({
            initial: data,
          })
          this.initiater()
        }
        })

        this.socket.on("maker_start", ()=>{
          if(this.state.make==true){
          this.setState({
            hideOpening: true,
          })
        }
        })

    

        this.socket.on("resume_gamer", (()=>{
          this.resumeGame(false)
        }))

        this.socket.on("you_winr", (()=>{
          this.deleter()
          this.setState({
            closing: true,
            winner: true,
            stop: true,
          })
        }))

        this.socket.on("review_gamer", (()=>{
          this.reviewGame(false)
        }))

        this.socket.on("jump_tor", ((data)=>{
          this.jumpTo(data, false)
        }))
        this.socket.on("recieve_message", (data=>{
          const history = this.state.history.slice(0, this.state.stepNumber+1)
          this.setState({
            whiteNext: !this.state.whiteNext,
            count:0,
            history: history.concat([{
              squares: data.squares,
              blackPieces: data.blackPieces,
              whitePieces: data.whitePieces,
              wcount: data.bcount,
              bcount: data.wcount,
            }]),
          stepNumber: history.length
          })
      }))
      }

      componentWillUnmount() {
      }
   
      

      jumpTo(step, original){
        const wcount = this.state.history[step].wcount
        const bcount = this.state.history[step].bcount
        if(step%2 ==0 && this.state.make){
          var go =true
        }else if(step%2 ==1 && !this.state.make){
          var go =true
        }else{
          var go = false
        }
        var wcount1 = wcount
        var bcount1 = bcount
        if(!this.state.make){
          wcount1 = bcount
          bcount1 = wcount
        }
        if(this.state.stop == true){
          this.setState({
              wcount: wcount1,
              bcount: bcount1,
              stepNumber:step,
              whiteNext: go
          })
          if(original){
            if(this.state.over ==false){
          this.socket.emit("jump_tos", [this.state.room, step])
            }
          }
      }}

      checkCheck(king0, king1, squares, true1, bp, wp){
        if(this.state.make){
          var points = bp
        }else{
          var points = wp
        }
        if(check(king0,king1, squares, true1)){
          if(checkmate(king0, king1, squares, true1, points)){
            this.socket.emit("you_win",this.state.room)
            this.deleter()
            this.state.closing = true
            this.state.winner = false
            this.state.stop=true
          }
        }
        else if((this.state.initial*360 <= this.state.bcount && this.state.make==false) ||(this.state.initial*360 <= this.state.bcount && this.state.make==true) ){
          this.socket.emit("you_win",this.state.room) 
          this.deleter()
            this.state.closing = true
            this.state.winner = false
            this.state.stop=true

        }
        else if(points ==39){
          if(checkmate(king0, king1, squares, true1, points)){
            this.socket.emit("you_win",this.state.room)
            this.deleter()
            this.state.closing = true
            this.state.winner = false
            this.state.stop=true
          }
        }
      }

    render() {
    const history = this.state.history;
    if(this.state.stepNumber >0){
      this.state.review = true
    }else if(this.state.stop==false){
      this.state.review = false
    }
    const current = history[this.state.stepNumber];
    if(!this.state.make && this.state.stepNumber ==0){
      current.squares[7][3] = "wc"
      current.squares[7][4]="wq"
    }
    const whitepieces = history[this.state.stepNumber].whitePieces
    const blackpieces = history[this.state.stepNumber].blackPieces
    const wp = total_points(whitepieces)
    const bp = total_points(blackpieces)
    this.checkCheck(this.state.king[0],this.state.king[1], current.squares, true, bp, wp)
    let blackP
    let whiteP
      blackP = "Black: " + bp
      whiteP = "White: " + wp
      if(this.state.make){
      return (
        <div className="game">
          <Closing
          reviewGameOver = {this.reviewGameOver}
          newGame = {this.newGame}
          winner = {this.state.winner}
          make ={this.state.make}
          show = {this.state.closing}
          over = {this.state.over}
          />
          <Opening
          initialSetup = {this.state.initialSetup}
          make ={this.state.make}
          show = {this.state.hideOpening}
          setLength = {this.setLength.bind(this)}
          setRoom = {this.setRoom.bind(this)}
          startGame = {this.startGame.bind(this)}
          makeRoom = {this.makeRoom.bind(this)}
          findRoom = {this.findRoom.bind(this)}
          />
          <div id='right'>
            <div className='board-pad'></div>
              <div id='button-container'>
                <RevButton
                  winner = {this.state.winner}
                  show = {this.state.review}
                  ok ={true}
                  status={this.state.stop}
                  name ={"Resume"}
                  class ={"resume"}
                  reviewGame={this.resumeGame}
                  />
                  <RevButton
                  winner = {this.state.winner}
                  show = {this.state.review}
                  ok ={false}
                  status={this.state.stop}
                  name ={"Review"}
                  class ={"review"}
                  reviewGame={this.reviewGame}
                  />   
              </div>
              <div id='slide1'>
                <Slider
                    over = {this.state.over}
                    ok ={this.state.stop}
                    maxval={this.state.history.length-2}
                    curval={this.state.stepNumber-1}
                    jumpTo={this.jumpTo}/>
              </div>
              <div id='clock1'>
                <Clock
                initial ={this.state.initial}
                count ={this.state.wcount}
                color={"black__clock"}
                />
              </div>
              <div id='clock2'>
                <Clock
                initial ={this.state.initial}
                count ={this.state.bcount}
                color={"white__clock"}
                />
              </div>
            <div className='board-pad'></div>
          </div>
          <div id='center'>
            <div className='board-pad'></div>
                  <Board
                  squares ={current.squares}
                  make={this.state.make}
                  onClick={(i,j) => this.handleClick(i,j)} />
            <div className='board-pad'></div>
          </div>
          <div id='left'>
            <div className='board-pad'></div>
            <div id="point2">
              <div className='bpoints-display'>{blackP}</div>
                <Points
                make={!this.props.make}
                stepnumber = {this.state.stepNumber}
                team={"Black"}
                points={bp}
                pieces={blackpieces}
                />
              </div>
                <div id="point1">
                  <div className='wpoints-display'>{whiteP}</div>
                  <Points
                  make={!this.props.make}
                  stepnumber = {this.state.stepNumber}
                  points={wp}
                  pieces={whitepieces}
                  team={"White"}
                  />
                </div>
              <div className='board-pad'></div>
          </div>
        </div>
      );}
      else{
        return (
          <div className="game">
            <Closing
            reviewGameOver = {this.reviewGameOver}
            newGame = {this.newGame}
            winner = {this.state.winner}
            make ={this.state.make}
            show = {this.state.closing}
            over = {this.state.over}
            />
            <Opening
            initial = {this.state.initial}
            make ={this.state.make}
            show = {this.state.hideOpening}
            setLength = {this.setLength.bind(this)}
            setRoom = {this.setRoom.bind(this)}
            startGame = {this.startGame.bind(this)}
            makeRoom = {this.makeRoom.bind(this)}
            findRoom = {this.findRoom.bind(this)}
            />
            <div id='right'>
              <div className='board-pad'></div>
              <div id='button-container'>
                <RevButton
                  winner = {this.state.winner}
                  show = {this.state.review}
                  ok ={true}
                  status={this.state.stop}
                  name ={"Resume"}
                  class ={"resume"}
                  reviewGame={this.resumeGame}
                  />
                  <RevButton
                  winner = {this.state.winner}
                  show = {this.state.review}
                  ok ={false}
                  status={this.state.stop}
                  name ={"Review"}
                  class ={"review"}
                  reviewGame={this.reviewGame}
                  /> 
              </div>
              <div id='slide1'>
                <Slider
                    over = {this.state.over}
                    ok ={this.state.stop}
                    maxval={this.state.history.length-2}
                    curval={this.state.stepNumber-1}
                    jumpTo={this.jumpTo}/>
              </div>
              <div id='clock1'>  
                <Clock
                initial ={this.state.initial}
                count ={this.state.wcount}
                color={"white__clock"}
                />
              </div>
              <div id='clock2'>
                <Clock
                initial ={this.state.initial}
                count ={this.state.bcount}
                color={"black_clock"}
                />
              </div>
              <div className='board-pad'></div>
            </div>
            <div id='center'>
              <div className='board-pad'></div>
                <div className="game-board">
                  <Board
                  make = {this.state.make}
                  squares ={current.squares}
                  onClick={(i,j) => this.handleClick(i,j)} />
              </div>
              <div className='board-pad'></div>
            </div>
            <div id='left'>
              <div className='board-pad'></div> 
              <div id="point1">
              <div className='bpoints-display'>{blackP}</div>
                <Points
                stepnumber = {this.state.stepNumber}
                team={"Black"}
                points={bp}
                pieces={blackpieces}
                />
              </div>
              <div id="point2">
              <div className='wpoints-display'>{whiteP}</div>
                <Points
                stepnumber = {this.state.stepNumber}
                points={wp}
                pieces={whitepieces}
                team={"White"}
                />
              </div>
              <div className='board-pad'></div>
            </div>
          </div>
        );
      }
    }
  }



  

  

export default Game