import React from 'react';
import './clock.css';


class Clock extends React.Component{


    render(){

        
        var count = parseInt(this.props.count)
        var total = this.props.initial*60 - (count/6)
        var minute = Math.floor(total/60)
        var second = Math.floor(total%60)
        var sec = (count%360).toString() +"deg"
        var min = ((count/60)%360).toString() + "deg"
        if(second <10){
            var return1 = minute.toString() + ":0" +second.toString()
        }else{
            var return1 = minute.toString() + ":" +second.toString()
        }
    return(
        <div className='clock_container'>
      <div className='analog'>
          <div className='tick' id='tick1'>|</div>
          <div className='tick' id='tick2'>|</div>
          <div className='tick' id='tick3'>|</div>
          <div className='tick' id='tick4'>|</div>
          <div className='tick' id='tick5'>|</div>
          <div className='tick' id='tick6'>|</div>
          <div className='tick' id='tick7'>|</div>
          <div className='tick' id='tick8'>|</div>
          <div className='tick' id='tick9'>|</div>
          <div className='tick' id='tick10'>|</div>
          <div className='tick' id='tick11'>|</div>
          <div className='tick' id='tick12'>|</div>
          <div className='tick' id='tick13'>|</div>
          <div className='tick' id='tick14'>|</div>
          <div className='tick' id='tick15'>|</div>
          <div className='tick' id='tick16'>|</div>
          <div className='tick' id='tick17'>|</div>
          <div className='inner_clock'></div>
          <div id={this.props.color + "hand-second"} style={{transform: "rotate(" + sec+")"}} className='clock-hand-second'></div>
          <div id={this.props.color + "hand-minute"} style={{transform: "rotate(" + min+")"}} className='clock-hand-minute'></div>
          <div className='clock_center'></div>
      </div>
      <div id={this.props.color + "digital"} className='digital'>
          {return1}
      </div>
        </div>
    )
    }     
  }

export default Clock