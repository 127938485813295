import React from 'react';
import './points.css';
import bsq from "./img/bsq.png"
import wsq from "./img/wsq.png"
import {image_decide,image_opacity,calcPoints, image_size} from './board_functions';


function Square(props) {
  return (
    <div 
    className="mini_square" 
    onClick={props.onClick}>
      <img className='squarepic' src ={props.src} alt={"square"}/>
      <img src={image_decide(props.make, props.value)} 
      style={{opacity:image_opacity(props.value)}} 
      className={image_size(props.value)} 
      />
    </div>
  );
}

class Points extends React.Component{

  constructor(props){
    super(props);
    this.state = {
          squares:[
            ["ee","ee","ee","ee",],
            ["ee","ee","ee","ee",],
            ["ee","ee","ee","ee",],
            ["ee","ee","ee","ee",]]
    }
  }

  renderSquare(i,j,w) {
    return(<Square
    make = {this.props.make}
    src={w}
    value={this.state.squares[i][j]}
    //onClick={() => this.props.onClick(i,j)}  
    />);
  }

    render(){
      var squares = 
      [["ee","ee","ee","ee",],
      ["ee","ee","ee","ee",],
      ["ee","ee","ee","ee",],
      ["ee","ee","ee","ee",]]
      var length1 = this.props.pieces.length
      var counter =0
      for(var x=0; x<4; x++){
        for(var y=0; y<4; y++){
        if(counter >length1-1){
          squares[x][y] = "ee"
        }else{
        squares[x][y] = this.props.pieces[counter]
        }
        counter +=1
        }
      }
      this.state.squares = squares
    return(
      <div className='pointstotal'>
          <div className="board-row2">
            {this.renderSquare(0,0, bsq)}
            {this.renderSquare(0,1, wsq)}
            {this.renderSquare(0,2, bsq)}
            {this.renderSquare(0,3, wsq)}
          </div>
          <div className="board-row2">
            {this.renderSquare(1,0, wsq)}
            {this.renderSquare(1,1, bsq)}
            {this.renderSquare(1,2, wsq)}
            {this.renderSquare(1,3, bsq)}
          </div>
          <div className="board-row2">
            {this.renderSquare(2,0, bsq)}
            {this.renderSquare(2,1, wsq)}
            {this.renderSquare(2,2, bsq)}
            {this.renderSquare(2,3, wsq)}
          </div>
          <div className="board-row2">
            {this.renderSquare(3,0, wsq)}
            {this.renderSquare(3,1, bsq)}
            {this.renderSquare(3,2, wsq)}
            {this.renderSquare(3,3, bsq)}
          </div>
        </div>
    )
    }
  }

export default Points