import React from 'react';
import './Slider.css';

class Slider extends React.Component{

    render(){
      if(this.props.over==true){
        var maxval1 = this.props.maxval+1
        var curval1 = this.props.curval +1
      }else{
        var maxval1 = this.props.maxval
        var curval1 = this.props.curval
      }
      if(this.props.ok == true){
    return(
      <input key={maxval1} className='slider1' id='slide1' type={"range"}
      min={0}
      defaultValue={curval1}
      max={maxval1}
      disabled={false}
      //onChange={()=> this.props.jumpTo(1)}
      onChange={(event)=> this.props.jumpTo(event.target.value, true)}
      />
    )
      }else{
        return(
        <input style={{opacity:0}} key={maxval1} className='slider1' id='slide1' type={"range"}
      min={0}
      defaultValue={curval1}
      max={maxval1}
      disabled={false}
      //onChange={()=> this.props.jumpTo(1)}
      onChange={(event)=> this.props.jumpTo(event.target.value, true)}
      />
        )
      }
    }
  }

export default Slider